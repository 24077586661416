import { render, staticRenderFns } from "./FilterWallets.vue?vue&type=template&id=c7c01512&scoped=true"
import script from "./FilterWallets.vue?vue&type=script&lang=ts"
export * from "./FilterWallets.vue?vue&type=script&lang=ts"
import style0 from "./FilterWallets.vue?vue&type=style&index=0&id=c7c01512&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c7c01512",
  null
  
)

export default component.exports