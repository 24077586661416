































































































































import {Component, Mixins, Vue} from 'vue-property-decorator'
import NavbarMenu from '@/components/NavbarMenu.vue'
import AppFooter from '@/components/AppFooter.vue'
import {MetaInfo} from 'vue-meta'
import WalletCard from '@/components/cards/WalletCard.vue'
import {WalletCollection} from '@/model/collection/WalletCollection'
import {ListWalletSchema} from '@/schema/resource/Wallet/ListWalletSchema'
import WalletList from '@/components/lists/WalletList.vue'
import BannerList from '@/components/lists/BannerList.vue'
import WalletNavHeaderMenu from '@/components/wallet/WalletNavHeaderMenu.vue'
import FilterWallets from '@/components/filters/FilterWallets.vue'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'

@Component({
  components: {
    WalletNavHeaderMenu,
    FilterWallets,
    WalletList,
    WalletCard,
    AppFooter,
    NavbarMenu,
    BannerList,
  },
})
export default class WalletsView extends Mixins(MixinScreenSize) {
  schema = new ListWalletSchema()

  collectionMobile = new WalletCollection()
  collectionDesktop = new WalletCollection()
  collectionWeb = new WalletCollection()
  collectionHardware = new WalletCollection()

  activatedName: string | null = null

  async mounted() {
    window.scrollTo(0, 0)
    await this.applyResources()
    await this.populateResources()
  }

  async applyResources() {
    if (this.isMobile) {
      this.collectionMobile.perPage = null
      this.collectionDesktop.perPage = null
      this.collectionWeb.perPage = null
      this.collectionHardware.perPage = null
    }

    this.collectionMobile.isMobile = true
    this.collectionMobile.orderBy = 'order'

    this.collectionDesktop.isDesktop = true
    this.collectionDesktop.orderBy = 'order'

    this.collectionWeb.isWeb = true
    this.collectionWeb.orderBy = 'order'

    this.collectionHardware.isHardware = true
    this.collectionHardware.orderBy = 'order'
  }

  isActivated(name: string) {
    return name === this.activatedName
  }

  deactivate() {
    this.activatedName = null
  }

  getDisabled() {
    const collectionsEmpties = {
      mobile: this.collectionMobile.isEmpty(),
      desktop: this.collectionDesktop.isEmpty(),
      hardware: this.collectionHardware.isEmpty(),
      web: this.collectionWeb.isEmpty(),
    }

    return Object.entries(collectionsEmpties)
      .filter(([, value]) => !!value)
      .map(([key]) => key)
  }

  handleActivate(name: string) {
    this.activatedName = name
  }

  async populateResources() {
    const promises: Array<Promise<any>> = [
      this.collectionMobile.expand(),
      this.collectionDesktop.expand(),
      this.collectionWeb.expand(),
      this.collectionHardware.expand(),
    ]

    await this.$await.run('loadWallets', () => Promise.all(promises))
    await this.$nextTick()
  }

  metaInfo(): MetaInfo {
    return {
      title: this.$translate('metadata.title.wallets'),
    }
  }
}
