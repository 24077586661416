




































import {Component, Prop, Vue, Emit} from 'vue-property-decorator'

@Component
export default class WalletNavHeaderMenu extends Vue {
  @Prop({type: String}) activatedName!: string
  @Prop({type: Array}) disabledNames!: string[]

  activatedClass(name: string) {
    return {
      'wallet-nav-header-menu__btn--active': name === this.activatedName,
      'wallet-nav-header-menu__btn--disabled': this.disabledNames.includes(
        name
      ),
    }
  }

  @Emit('activate')
  handleOnClick(event: PointerEvent, name: string) {
    event.preventDefault()
    const element = document.getElementById(name)
    window.scrollBy({
      top: (element?.getBoundingClientRect().top || 0) - 50,
      behavior: 'smooth',
    })
    return name
  }
}
