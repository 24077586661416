


































































import {Component, Prop, Vue} from 'vue-property-decorator'
import {WalletCollection} from '@/model/collection/WalletCollection'
import {FilterWalletSchema} from '@/schema/resource/Wallet/FilterWalletSchema'
import FilterWalletFiltered from '@/components/filters/FilterWalletFiltered.vue'
import {classToPlain} from 'class-transformer'
import {debounce} from 'lodash'
import FilterWalletMobile from '@/components/filters/FilterWalletMobile.vue'

@Component({
  components: {
    FilterWalletMobile,
    FilterWalletFiltered,
  },
})
export default class FilterWallet extends Vue {
  @Prop({type: Array, required: true}) listCollections!: WalletCollection[]
  @Prop({type: Number, default: 1000}) debounceTimer!: number

  schema = new FilterWalletSchema()
  collection = new WalletCollection()
  showModal: boolean = false

  async created() {
    await this.populateFilterOptions()
  }

  async populateFilterOptions() {
    this.collection.resource = this.schema

    const promises: Array<Promise<any>> = [
      this.collection.resource.collectionBlockchainVersion.queryAsPage(),
      this.collection.resource.collectionDappConnection.queryAsPage(),
      this.collection.resource.collectionPlatformType.queryAsPage(),
    ]

    await this.$await.run('softQuery', () => Promise.all(promises))
  }

  async doFilter() {
    this.$emit('submit')

    this.listCollections.forEach(listCollection => {
      Object.assign(listCollection, {
        ...classToPlain(this.collection),
        isMobile: listCollection.isMobile,
        isDesktop: listCollection.isDesktop,
        isWeb: listCollection.isWeb,
        isHardware: listCollection.isHardware,
      })
    })

    await this.$await.run('loadWallets', () =>
      Promise.all(
        this.listCollections.map(listCollection =>
          listCollection.queryToFirstPage()
        )
      )
    )

    this.showModal = false
  }

  doClear() {
    this.collection.walletBlockchainVersion = []
    this.collection.walletPlatformType = []
    this.collection.walletDappConnection = []
    this.doFilter()
  }

  inputClass(): string {
    return 'multiselect--hide-tags'
  }

  private toggleModal(): void {
    this.showModal = !this.showModal
  }

  get vBind() {
    return {...this.$attrs}
  }

  get vOn() {
    const listeners = {...this.$listeners}
    delete listeners.input
    return {...listeners}
  }

  get querySearch() {
    return debounce(() => this.debounceQuerySearch(), this.debounceTimer)
  }

  async debounceQuerySearch() {
    this.listCollections.forEach(listCollection => {
      listCollection.search = this.collection.search
    })

    return this.$await.run('loadWallets', () =>
      Promise.all(
        this.listCollections.map(listCollection => listCollection.querySearch())
      )
    )
  }
}
