









































































import {Component, Prop, Mixins} from 'vue-property-decorator'
import Swiper from 'swiper'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {WalletCollection} from '@/model/collection/WalletCollection'
import WalletCard from '@/components/cards/WalletCard.vue'
import AdapTableWrapper from '@simpli/vue-adap-table'

@Component({
  components: {WalletCard},
})
export default class WalletList extends Mixins(MixinScreenSize) {
  @Prop({type: String, required: true}) title!: string
  @Prop({type: Boolean, required: true}) activated!: boolean
  @Prop({type: String, required: true}) spinner!: string
  @Prop({type: WalletCollection, required: true}) collection!: WalletCollection

  isBeginning = false
  isEnd = false

  swiper!: Swiper

  get cleanTitle() {
    return this.title.replace(/[^a-zA-Z\d]+/g, '')
  }

  get swiperOption() {
    return {
      pagination: {
        el: `.${this.cleanTitle}`,
        clickable: true,
      },
      breakpoints: AdapTableWrapper.options.swiperOptions?.breakpoints,
    }
  }

  async initSwiper(swiper: Swiper) {
    this.swiper = swiper
    this.updateEvent()
  }

  created() {
    this.applyCollection()
  }

  applyCollection() {
    this.collection.perPage = 10
    this.collection.orderBy = 'order'
  }

  updateEvent() {
    this.isBeginning = this.swiper.isBeginning
    this.isEnd = this.swiper.isEnd
  }

  prevSlide() {
    if (!this.swiper.isBeginning) {
      this.swiper.slidePrev()
    }
    this.updateEvent()
  }

  nextSlide() {
    if (!this.swiper.isEnd) {
      this.swiper.slideNext()
    }
    this.updateEvent()
  }
}
