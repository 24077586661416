









































import {Component, Mixins, Prop, Vue} from 'vue-property-decorator'
import {Wallet} from '@/model/resource/Wallet'
import PercentageProgress from '@/components/misc/PercentageProgress.vue'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'

@Component({
  components: {PercentageProgress},
})
export default class WalletCard extends Mixins(MixinScreenSize) {
  @Prop({type: Wallet, required: true}) wallet!: Wallet

  formatDescription(description: string): string {
    return description.replace('\n', '<br>')
  }
}
